import React from "react"
import GlobalStyle from "../assets/styles/GlobalStyle"
import Searchbar from "./Searchbar"
import Header from "./Header"
import Navbar from "./Navbar"
import SubNavBar from "./SubNavBar"
import BottomBar from "./BottomBar"
import Footer from "./Footer"
import styled from "styled-components"
import Sidebar from "./Sidebar"
import { ThemeProvider, StyledEngineProvider } from "@mui/material"
import { ThemeProvider as StyledThemeProvider } from "styled-components"
import CssBaseline from "@mui/material/CssBaseline"
import theme from "../assets/styles/theme"
import { Container, Box } from "@mui/material"
import { useKeycloak } from "@react-keycloak/web"
import { useIdleTimer } from "react-idle-timer"

const PageContainer = styled(Box)`
    width: var(--container-width);
    max-width: var(--max-width);
    margin: 0 auto;
    min-height: calc(100vh - (6rem + 4rem));
`

const Page = styled(Box)`
    margin-top: 1rem;
`

const Layout = ({ children, pageContext }) => {
    // Using Object destructuring
    const { keycloak, initialized } = useKeycloak()

    const onIdle = () => {
        // Close Modal Prompt
        // Do some idle action like log out your user
        keycloak.logout()
    }

    const onActive = event => {
        // Close Modal Prompt
        // Do some active action
    }

    const idleTimer = useIdleTimer({ onIdle, onActive })
    if (pageContext.errorPage) {
        return (
            <>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <StyledThemeProvider theme={theme}>
                            <CssBaseline />
                            <GlobalStyle />
                            {children}
                        </StyledThemeProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </>
        )
    }
    return (
        <>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <StyledThemeProvider theme={theme}>
                        <CssBaseline />
                        <GlobalStyle />
                        <Header />
                        <Navbar />
                        <SubNavBar />
                        <PageContainer>
                            <Page>{children}</Page>
                        </PageContainer>

                        <BottomBar />
                        <Footer />
                    </StyledThemeProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </>
    )
}

export default Layout

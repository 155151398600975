import EmailIcon from "@mui/icons-material/Email"
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled"
import { Box, Typography, Stack } from "@mui/material"
import React from "react"
import styled from "styled-components"

const Header = () => {
    return (
        <Wrapper>
            <StyledHeader>
                <Stack direction="row" alignItems="left" spacing={1}>
                    <PhoneEnabledIcon fontSize="small" sx={{ color: "secondary.dark" }} />
                    <Typography variant="body2">+7 (812) 200-50-25, с 09:00 до 18:00 мск</Typography>
                </Stack>
                <Stack direction="row" alignItems="left" spacing={1}>
                    <EmailIcon fontSize="small" sx={{ color: "secondary.dark" }} />
                    <Typography variant="body2">sales@skpiter.com</Typography>
                </Stack>
            </StyledHeader>
        </Wrapper>
    )
}

const StyledHeader = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: var(--container-width);
    max-width: var(--max-width);
`

const Wrapper = styled.header`
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
`

export default Header

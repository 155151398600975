import React from "react"
import styled from "styled-components"

const Loading = () => {
    return (
        <Wrapper>
            <section className="container container-center">
                <div className="loading"></div>
            </section>
        </Wrapper>
    )
}

const Wrapper = styled.main`
    @keyframes spinner {
        to {
            transform: rotate(360deg);
        }
    }

    .loading {
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        border: 3px solid #ccc;
        border-top-color: var(--primary-500);
        animation: spinner 0.7s linear infinite;
    }
    .loading {
        margin: 0 auto;
    }
`

export default Loading

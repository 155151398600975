import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

const initialState = {
    orderItems: [],
    amount: 0,
    total: 0,
    isLoading: true,
}

const orderSlice = createSlice({
    name: "orderSlice",
    initialState,
    reducers: {
        clearOrder: state => {
            state.orderItems = []
        },
        addItem: (state, { payload }) => {
            state.orderItems.push(payload)
        },
        removeItem: (state, { payload }) => {
            state.orderItems = state.orderItems.filter(item => !(item.id === payload.id && item.pos === payload.pos))
        },

        setAmount: (state, { payload }) => {
            const orderItem = state.orderItems.find(item => item.id === payload.id && item.pos === payload.pos)
            orderItem.amount = payload.amount
        },
    },
})

export const { clearOrder, addItem, removeItem, setAmount } = orderSlice.actions

export default orderSlice.reducer

import { createTheme } from "@mui/material"

const globalTheme = createTheme({
    palette: {
        common: {
            black: "#222",
        },
        primary: {
            main: "#645cff",
            dark: "#4640b3",
        },
        secondary: {
            main: "#f3ca40",
            dark: "#d3a50d",
        },
        background: {
            paper: "#fff",
            default: "#fafbfc",
        },
    },
})

const theme = createTheme({
    ...globalTheme,

    typography: {
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            '"Helvetica"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
        ].join(","),
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    "&.MuiButtonBase-root": {
                        textTransform: "none",
                        transition: "0.3s ease-in-out all",
                    },
                    "&.MuiButtonBase-root:hover": {
                        color: globalTheme.palette.primary.dark,
                    },
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    "&.MuiTypography-root": {
                        maxWidth: "100%",
                    },
                    h4: {
                        marginBottom: "2rem",
                    },
                },
                h4: {
                    marginBottom: "2rem",
                },
                h5: {
                    marginTop: "1rem",
                    marginBottom: "1rem",
                },
            },
        },
    },
})

export default theme

import React, { useContext } from "react"
import styled from "styled-components"
import { GatsbyContext } from "../context/gatsby"
import { navigate } from "gatsby-link"
import { AppBar, Toolbar, Button, Box, Typography } from "@mui/material"

const SubNavBar = () => {
    const { subLinks } = useContext(GatsbyContext)

    return (
        <Wrapper>
            <AppBar
                sx={{
                    position: "static",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "grey.200",
                    height: "2.75rem",
                    minHeight: "2.75rem",
                    paddingRight: "0 !important",
                }}
                elevation={0}
            >
                <Box sx={{ width: "90vw", maxWidth: "1100px" }}>
                    <Toolbar sx={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                            {subLinks.map(link => (
                                <Button
                                    key={link.page}
                                    sx={{ my: 2, color: "black", display: "block" }}
                                    onClick={() => {
                                        navigate(link.route)
                                    }}
                                >
                                    {link.page}
                                </Button>
                            ))}
                        </Box>
                    </Toolbar>
                </Box>
            </AppBar>
        </Wrapper>
    )
}

const Wrapper = styled.main`
    .navbar {
        height: 2.75rem;
        background: var(--primary-0);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .nav-center {
        width: 90vw;
        max-width: var(--max-width);
        display: flex;
        align-items: center;
    }

    .nav-links {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    @media screen and (min-width: 992px) {
        .navbar {
        }
        .nav-center {
        }
    }
`

export default SubNavBar

import { Box, Button, Typography, TextField, IconButton, InputAdornment } from "@mui/material"
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined"
import React from "react"
import { navigate } from "gatsby-link"

const Sidebar = () => {
    return (
        <Box
            sx={{
                backgroundColor: "grey.200",
                minHeight: "30vh",
                height: "30%",
                width: "240px",
                minWidth: "240px",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Button
                variant="contained"
                onClick={() => {
                    navigate("/goods")
                }}
            >
                Каталог компонентов
            </Button>
            <Typography variant="h6">Промокод</Typography>
            <Typography>Введите промокод для получения дополнительной информации</Typography>
            <TextField
                label=""
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton edge="end" color="primary">
                                <KeyboardArrowRightOutlinedIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
    )
}

export default Sidebar

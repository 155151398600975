import { Box, Divider, Typography, Stack } from "@mui/material"
import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

const Footer = () => {
    return (
        <Wrapper>
            <Stack direction="row" sx={{ marginRight: "10rem" }}>
                <Typography color="white" sx={{ fontSize: "inherit" }}>
                    &copy; {new Date().getFullYear()}
                </Typography>
                <Typography color="primary.main" sx={{ fontSize: "inherit" }}>
                    {" "}
                    5025.PRO
                </Typography>
                <Typography color="white" sx={{ fontSize: "inherit" }}>
                    . Все права защищены
                </Typography>
            </Stack>

            <Stack direction="row" spacing={2} divider={<StyledDivider orientation="vertical" flexItem />}>
                <StyledLink to="/rules">Правила использования</StyledLink>
                <StyledLink to="/privacy">Политика конфиденциальности</StyledLink>
                <StyledLink to="/cookies">Cookies</StyledLink>
            </Stack>
        </Wrapper>
    )
}

const Wrapper = styled.footer`
    text-align: center;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--black);
    color: var(--white);
    font-size: var(--smallText);
`

const StyledLink = styled(Link)`
    text-decoration: none;
    color: var(--white);
    transition: var(--transition);
    &:hover {
        color: var(--primary-500);
    }
`
const StyledDivider = styled(Divider)`
    background-color: var(--grey-500);
`

export default Footer

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

const initialState = {
    invoiceItems: [],
    amount: 0,
    total: 0,
    isLoading: true,
}

const invoiceSlice = createSlice({
    name: "invoicesRegistry",
    initialState,
    reducers: {
        clearInvoicesRegistry: state => {
            state.invoiceItems = []
        },
        addItem: (state, { payload }) => {
            state.invoiceItems.push(payload)
        },
        removeItem: (state, { payload }) => {
            state.invoiceItems.splice(payload.index, 1)
        },

        setAmount: (state, { payload }) => {
            const invoiceItem = state.invoiceItems[payload.index]
            invoiceItem.amount = payload.amount
        },
    },
})

export const { clearInvoicesRegistry, addItem, removeItem, setAmount } = invoiceSlice.actions

export default invoiceSlice.reducer

import React from "react"
import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
*,
::after,
::before {
    box-sizing: border-box;
}

:root {
    /* COLORS  */
    /* primary */
    --primary-0: #D8D6FF;
    --primary-50: #C5C2FF;
    --primary-100: #B1ADFF;
    --primary-200: #9E99FF;
    --primary-300: #8B85FF;
    --primary-400: #7770FF;
    /* main */
    --primary-500: #645cff;
    --primary-600: #554ed9;
    --primary-700: #4640b3;
    --primary-800: #37338c;
    --primary-900: #282566;
    --primary-1000: #191740;
    --primary-1100: #0a0919;
    /* grey */
    --grey-0: #fafbfc;
    --grey-50: #f2f4f8;
    --grey-100: #eaedf3;
    --grey-200: #e2e7ef;
    --grey-300: #dbe0ea;
    --grey-400: #d3dae6;
    --grey-500: #cbd3e1;
    --grey-600: #adb3bf;
    --grey-700: #8e949e;
    --grey-800: #70747c;
    --grey-900: #51545a;
    --grey-1000: #333538;
    --grey-1100: #141516;

    /* rest */
    --black: #222;
    --white: #fff;
    --red-light: #f8d7da;
    --red-dark: #842029;
    --green-light: #d1e7dd;
    --green-dark: #0f5132;
    /* box shadows */
    --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
    --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
        0 10px 10px -5px rgba(0, 0, 0, 0.04);
    /* typography */
    --defaultFontSize: 87.5%;
    --headingFont: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
        Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    --bodyFont: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
        sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    --smallText: 0.8em;
    --extraSmallText: 0.7em;
    /* rest */
    --backgroundColor: var(--grey-0);
    --textColor: var(--grey-1000);
    --borderRadius: 0.25rem;
    --letterSpacing: 0;
    --transition: 0.3s ease-in-out all;
    --max-width: 1200px;
    --fixed-width: 600px;
    --container-width: 90vw;
}
html {
    font-size: var(--defaultFontSize);
}
@media (min-width: 576px) {
    :root {
        --defaultFontSize: 100%;
    }
}
@media (min-width: 768px) {
    :root {
        --defaultFontSize: 100%;
    }
}

@media (min-width: 992px) {
    :root {
        --defaultFontSize: 100%;
    }
}
@media (min-width: 1200px) {
    :root {
        --defaultFontSize: 100%;
    }
}
@media (min-width: 1400px) {
    :root {
        --defaultFontSize: 100%;
    }
}

body {
    background: var(--backgroundColor);
    color: var(--textColor);
    font-family: var(--bodyFont);
    line-height: 1.5;
    white-space: pre-wrap;

}

p {
    margin: 0;
    margin-bottom: 1rem;
    max-width: 40em;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    margin-bottom: 1.38rem;
    font-family: var(--headingFont);
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: var(--letterSpacing);
}

h1 {
    font-size: 3.052rem;
}

h2 {
    font-size: 2.441rem;
}

h3 {
    font-size: 1.953rem;
}

h4 {
    font-size: 1.563rem;
}

h5 {
    font-size: 1.25rem;
}

small,
.text-small {
    font-size: var(--smallText);
}

ul {
    padding: 0;
    list-style-type: none;
}
a {
    text-decoration: none;
}
.img {
    width: 100%;
    display: block;
    object-fit: cover;
}


hr {
    border-top: var(--line);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


/* Basic Classes */

.container {
    padding: 5rem 0;
}
.container-center {
    width: 90vw;
    max-width: var(--max-width);
    margin: 0 auto;
}



form > *:last-child {
    margin-bottom: 0;

}



`

export default GlobalStyle

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

const initialState = {
    reqItems: [],
    amount: 0,
    total: 0,
    isLoading: true,
}

const reqSlice = createSlice({
    name: "req",
    initialState,
    reducers: {
        clearReq: state => {
            state.reqItems = []
        },
        addItem: (state, { payload }) => {
            state.reqItems.push(payload)
        },
        removeItem: (state, { payload }) => {
            state.reqItems = state.reqItems.filter(item => !(item.id === payload.id))
        },

        setAmount: (state, { payload }) => {
            const reqItem = state.reqItems.find(item => item.id === payload.id)
            reqItem.amount = payload.amount
        },
    },
})

export const { clearReq, addItem, removeItem, setAmount } = reqSlice.actions

export default reqSlice.reducer

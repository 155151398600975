import { useKeycloak } from "@react-keycloak/web"
import React, { useState } from "react"
import styled from "styled-components"
import { BsSearch } from "react-icons/bs"
import { navigate } from "gatsby"
import SearchIcon from "@mui/icons-material/Search"
import { InputBase, alpha, IconButton } from "@mui/material"

const Searchbar = () => {
    const [pn, setPn] = useState("")

    const handleSubmit = event => {
        event.preventDefault()
        navigate("/searchresults", {
            state: { pn },
        })
    }

    return (
        <form method="post" onSubmit={event => handleSubmit(event)}>
            <Search sx={{}}>
                <StyledInputBase
                    value={pn || ""}
                    onChange={e => setPn(e.target.value)}
                    placeholder="p/n, наименование, производитель"
                    sx={{ color: "white" }}
                />
                {/* <SearchIconWrapper>

                </SearchIconWrapper> */}
                <StyledIconButton color="inherit" onClick={event => handleSubmit(event)}>
                    <SearchIcon />
                </StyledIconButton>
            </Search>
        </form>
    )

    return (
        <Wrapper>
            <div>
                <form method="post" className="form-search" onSubmit={event => handleSubmit(event)}>
                    <div className="form-search-row">
                        <input
                            type="text"
                            placeholder="p/n, наименование, производитель"
                            name="search"
                            autoComplete="off"
                            onChange={e => setPn(e.target.value)}
                        ></input>
                        <button type="submit" onClick={handleSubmit}>
                            <BsSearch />
                        </button>
                    </div>
                </form>
            </div>
        </Wrapper>
    )
}

const Search = styled.div`
    position: relative;
    width: auto;
    min-width: 400px;
    border-radius: var(--borderRadius);
    background-color: ${props => alpha(props.theme.palette.background.default, 0.15)};
    &:hover {
        background-color: ${props => alpha(props.theme.palette.background.paper, 0.25)};
    }
    margin-right: ${props => props.theme.spacing(2)};
    margin-left: ${props => props.theme.spacing(2)};
`

const StyledIconButton = styled(IconButton)`
    padding: ${props => props.theme.spacing(0, 2)};
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`

const SearchIconWrapper = styled.div`
    padding: ${props => props.theme.spacing(0, 2)};
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
`

const StyledInputBase = styled(InputBase)`
    .MuiInputBase-input {
        padding: ${props => props.theme.spacing(1, 0, 1, 1)};
        padding-right: calc(1em + ${props => props.theme.spacing(4)});
        width: 400px;
    }
    ::placeholder {
        color: var(--grey-600);
    }
`

const Wrapper = styled.div`
    .form-search {
        margin: auto;
        width: 100%;
        min-width: 300px;
        padding: 1rem 2.5rem;
    }
    @media only screen and (min-width: 768px) {
        .form-search {
            width: 400px;
        }
    }

    .form-search-row {
        display: flex;
        justify-content: center;
    }
    input {
        width: 100%;
        padding: 0.375rem 0.75rem;
        border-top-left-radius: var(--borderRadius);
        border-bottom-left-radius: var(--borderRadius);
        background: var(--grey-0);
        border-color: transparent;
        border: 1px solid var(--grey-300);
        outline: none;
    }
    input:focus {
        border: 1px solid var(--grey-700);
    }
    input:-webkit-autofill {
        background: var(--grey-0);
    }

    ::placeholder {
        color: var(--grey-600);
    }
    button {
        cursor: pointer;
        appearance: none;
        color: var(--black);
        background: #f3ca40;
        border: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: var(--borderRadius);
        border-bottom-right-radius: var(--borderRadius);
        letter-spacing: var(--letterSpacing);
        padding: 0.375rem 0.75rem;

        transition: var(--transition);
        text-transform: capitalize;
    }
    button:hover {
        color: var(--black);
        background: #d3a50d;
    }
`

export default Searchbar

import fetch from "cross-fetch"
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client"
import { createUploadLink } from "apollo-upload-client"
import { ApolloLink } from "apollo-link"
import apolloLogger from "apollo-link-logger"

const impersonationMiddleware = new ApolloLink((operation, forward) => {
    // add the recent-activity custom header to the headers
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            "x-impersonated-user": localStorage.getItem("lk_sub") || null,
        },
    }))

    return forward(operation)
})

const client = new ApolloClient({
    link: ApolloLink.from([
        apolloLogger,
        impersonationMiddleware,
        createUploadLink({
            uri: process.env.GATSBY_GRAPHQL_URL,
            fetch,
        }),
    ]),
    cache: new InMemoryCache(),
})

export default client

import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { FaLocationArrow, FaPhone, FaEnvelope } from "react-icons/fa"
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import { Grid, Box, Typography, Stack } from "@mui/material"
import EmailIcon from "@mui/icons-material/Email"
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled"

const BottomBar = () => {
    return (
        <Wrapper>
            <StyledBox>
                <Grid container>
                    <Grid item xs={3}>
                        <Stack>
                            <StyledTypography variant="h6">О нас</StyledTypography>
                            <StyledLink to="/about">О 5025.PRO</StyledLink>
                            <StyledLink to="/legal">Юридическая информация</StyledLink>
                        </Stack>
                    </Grid>
                    <Grid item xs={3}>
                        <Stack>
                            <StyledTypography variant="h6">Заказы</StyledTypography>
                            <StyledLink to="/delivery">Доставка</StyledLink>
                            <StyledLink to="/guaranties">Гарантии</StyledLink>
                            <StyledLink to="/faq">FAQ</StyledLink>
                        </Stack>
                    </Grid>
                    <Grid item xs={3}>
                        <Stack>
                            <StyledTypography variant="h6">Служба поддержки</StyledTypography>
                            <StyledLink to="/new">Для новых покупателей</StyledLink>
                            <StyledLink to="/search">Поиск и покупка</StyledLink>
                            <StyledLink to="/payment">Оплата и возврат</StyledLink>
                        </Stack>
                    </Grid>
                    <Grid item xs={3}>
                        <Stack>
                            <StyledTypography variant="h6">Контакты</StyledTypography>
                            <Stack direction="row" alignItems="left" spacing={1} sx={{ paddingBottom: "0.5rem" }}>
                                <LocationOnOutlinedIcon sx={{ color: "secondary.dark" }} />
                                <Typography>105318, Санкт-Петербург, пр.Тореза, д.44, корп.2, пом.33Н</Typography>
                            </Stack>
                            <Stack direction="row" alignItems="left" spacing={1} sx={{ paddingBottom: "0.5rem" }}>
                                <PhoneEnabledIcon sx={{ color: "secondary.dark" }} />
                                <Typography>+7 (812) 200-50-25</Typography>
                            </Stack>
                            <Stack direction="row" alignItems="left" spacing={1} sx={{ paddingBottom: "0.5rem" }}>
                                <EmailIcon sx={{ color: "secondary.dark" }} />
                                <Typography>sales@5025.pro</Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </StyledBox>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    background: var(--grey-100);
    display: flex;
    align-items: center;
    justify-content: center;
`
const StyledTypography = styled(Typography)`
    margin-bottom: 2rem;
`

const StyledBox = styled(Box)`
    width: 90vw;
    max-width: var(--max-width);
    margin-top: 2rem;
    margin-bottom: 2rem;
`

const StyledLink = styled(Link)`
    text-decoration: none;
    color: var(--black);
    transition: var(--transition);
    &:hover {
        color: var(--primary-500);
    }
    padding-bottom: 0.5rem;
`

export default BottomBar

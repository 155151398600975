import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

const GatsbyContext = React.createContext()

//Provider, Consumer

const GatsbyProvider = ({ children }) => {
    const linksQuery = graphql`
        {
            site {
                siteMetadata {
                    mainLinks {
                        page
                        route
                        store
                    }
                    subLinks {
                        page
                        route
                    }
                }
            }
        }
    `
    const {
        site: {
            siteMetadata: { mainLinks: mLinks, subLinks: sLinks },
        },
    } = useStaticQuery(linksQuery)

    const [mainLinks, setMainLinks] = useState(mLinks)
    const [subLinks, setSubLinks] = useState(sLinks)

    return <GatsbyContext.Provider value={{ mainLinks, subLinks }}>{children}</GatsbyContext.Provider>
}

export { GatsbyContext, GatsbyProvider }

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

const initialState = {
    cartItems: [],
    amount: 0,
    total: 0,
    isLoading: true,
}

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        clearCart: state => {
            state.cartItems = []
        },
        addItem: (state, { payload }) => {
            state.cartItems.push(payload)
        },
        removeItem: (state, { payload }) => {
            state.cartItems = state.cartItems.filter(item => !(item.id === payload.id && item.pos === payload.pos))
        },

        setAmount: (state, { payload }) => {
            const cartItem = state.cartItems.find(item => item.id === payload.id && item.pos === payload.pos)
            cartItem.amount = payload.amount
        },
    },
})

export const { clearCart, addItem, removeItem, setAmount } = cartSlice.actions

export default cartSlice.reducer
